import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { light as defaultTheme } from '../../theme';
import { size, margin, ring } from '../../utils';

const textareaStyles = css`
  display: block;
  position: relative;
  background-color: transparent;
  font-size: inherit;
  border-color: transparent;
  border-style: solid;
`;

const Root = styled('textarea').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  ${textareaStyles}
  width: ${(p) => (p.isFullWidth ? '100%' : 'auto')};
  padding: ${(p) => `${p.theme.spaces[1]} ${p.theme.spaces[2]}`};
  color: ${(p) => p.theme.input[p.mod].fg};
  background-color: ${(p) => p.theme.input[p.mod].bg};
  border-color: ${(p) =>
    p.isInvalid
      ? p.theme.input[p.mod].errorBorderColor
      : p.theme.input[p.mod].borderColor};
  font-size: ${(p) =>
    (p.size === 'xs' && 10) ||
    (p.size === 'sm' && 12) ||
    (p.size === 'md' && 14) ||
    (p.size === 'lg' && 14) ||
    (p.size === 'xl' && 18)}px;
  border-width: ${(p) => p.theme.input.borderWidth[p.borderWidth]};
  border-radius: ${(p) => p.theme.input.radius[p.radius]};
  transition: all ${(p) => p.theme.transitions[p.transition]};
  appearance: none;

  &:not([disabled]):focus {
    box-shadow: ${(p) =>
      ring({
        width: p.theme.ringWidth,
        color: p.theme.input.outline.ringColor,
      })};
  }

  &[disabled] {
    opacity: 0.6;
    cursor: auto;
  }

  &[readonly] {
    cursor: default;
    pointer-events: none;
  }

  &::placeholder {
    color: ${(p) => p.theme.input[p.mod].placeholder};
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    font-size: 16px;
  }

  ${size}
  ${margin}
`;

const Textarea = ({
  theme = defaultTheme,
  onChange = null,
  maxHeight = null,
  mod = 'outline',
  size = 'md',
  borderWidth = 'xs',
  radius = 'sm',
  transition = 'fast',
  isFullWidth = true,
  isInvalid = false,
  value = '',
  ...rest
}) => {
  const inputRef = useRef();

  const adjustHeight = () => {
    const textarea = inputRef.current;
    if (!textarea) return;

    const styles = getComputedStyle(textarea);
    textarea.style.height = 'inherit';

    const height =
      parseInt(styles.getPropertyValue('border-top-width'), 10) +
      textarea.scrollHeight +
      parseInt(styles.getPropertyValue('border-bottom-width'), 10);

    textarea.style.height = `${height}px`;
  };

  useEffect(() => {
    if (value) {
      adjustHeight();
    }
  }, [value]);

  const handleChange = (e) => {
    adjustHeight();
    if (onChange) onChange(e);
  };

  return (
    <Root
      ref={inputRef}
      onChange={handleChange}
      style={{
        maxHeight,
      }}
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      transition={transition}
      isFullWidth={isFullWidth}
      isInvalid={isInvalid}
      value={value}
      {...rest}
    />
  );
};

Textarea.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  onChange: PropTypes.func,
  maxHeight: PropTypes.string,
  mod: PropTypes.oneOf(['outline', 'smooth', 'unstyled', PropTypes.string]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', PropTypes.string]),
  borderWidth: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    PropTypes.string,
  ]),
  radius: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'full',
    PropTypes.string,
  ]),
  transition: PropTypes.oneOf([
    'fastest',
    'fast',
    'medium',
    'slow',
    'slowest',
    PropTypes.string,
  ]),
  isFullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

export default Textarea;
